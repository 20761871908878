import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import Bullet from "../../components/bullet"
import BulletList from "../../components/bullet-list"
import Heading from "../../components/heading"
import PageTitle from "../../components/page-title"
import Paragraph from "../../components/paragraph"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import VideoPlayer from "../../components/video-player"

const MitarbeitFsj = () => {
  const data = useStaticQuery(graphql`
    query {
      poster: file(relativePath: { eq: "videos/poster-fsj-kultur.jpg" }) {
        ...largeImage
      }
    }
  `)
  return (
    <Layout backdrop="aussen">
      <Seo
        title="FSJ im Hölderlinturm"
        description="Du interessierst dich für Literatur, bist aufgeschlossen, arbeitest gerne mit Menschen zusammen und möchtest mehr über die Arbeit eines Literaturmuseums erfahren? Dann bist du vielleicht genau die/der Richtige für ein Freiwilliges Soziales Jahr im Museum Hölderlinturm!"
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Mitarbeiten",
                link: "/mitarbeiten",
              },
              {
                title: "FSJ",
                link: "/mitarbeiten/fsj",
              },
            ]}
          />
          <PageTitle>FSJ im Hölderlinturm</PageTitle>
          <Paragraph dropcap={true}>
            Du interessierst dich für Literatur, bist aufgeschlossen, arbeitest
            gerne mit Menschen zusammen und möchtest mehr über die Arbeit eines
            Literaturmuseums erfahren? Dann bist du vielleicht genau die/der
            Richtige für ein Freiwilliges Soziales Jahr im Museum Hölderlinturm!
            Wir vergeben jedes Jahr zum Sommer/Herbst ein FSJ Kultur in unserem
            Museum.
          </Paragraph>
        </Stack>
        <Stack>
          <Heading as="h2" level={3}>
            Erhalte spannende Einblicke in die Arbeit eines Literaturmuseums
          </Heading>
          <Paragraph>
            Das Museum Hölderlinturm gehört zusammen mit dem Tübinger
            Stadtmuseum und dem Hesse-Kabinett zu den Museen der
            Universitätsstadt Tübingen. Mit einer großen multimedialen
            Dauerausstellung, wechselnden Sonderausstellungen und zahlreichen
            Bildungs- und Vermittlungsangeboten, insbesondere für Kinder und
            Jugendliche, setzt sich das Museum zum Ziel, den Dichter Friedrich
            Hölderlin und seine Literatur einem breiten Zielpublikum näher zu
            bringen. Die Einbeziehung von sozial benachteiligten Menschen sowie
            Menschen mit körperlichen oder geistigen Beeinträchtigungen ist
            dabei ein besonderes Anliegen des Museums.
          </Paragraph>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Deine Tätigkeitsfelder und Aufgaben
          </Heading>
          <Paragraph>
            Die FSJ-Stelle ist im Bereich Bildung und Vermittlung des Museums
            angesiedelt. Dieser umfasst sowohl Führungen, Workshops und
            Veranstaltungen vor Ort, als auch digitale Formate wie
            Video-Tutorials, interaktive Online-Angebote oder Fotos und Videos
            für Social Media. Der/die FSJler*in unterstützt die Organisation der
            digitalen und analogen Vermittlungsangebote und begleitet sie in der
            Praxis. Darüber hinaus wird der/die FSJler*in umfassende Einblicke
            in die Ausstellungs-, Veranstaltungs- und Vermittlungsarbeit des
            Museums erhalten.
          </Paragraph>
          <VideoPlayer src="fsj-kultur" poster={data.poster} />
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Unsere Rahmenbedingungen
          </Heading>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>
              Beginn: jeweils zum 1. September, auch schon ab 1. Juli möglich
            </Bullet>
            <Bullet>Bewerbungsschluss: jeweils zum 1. Juni</Bullet>
            <Bullet>
              Einblick in alle Arbeitsbereiche eines modernen, medial
              innovativen Museums
            </Bullet>
            <Bullet>
              enger Austausch mit den beiden FSJler*innen des Stadtmuseums
            </Bullet>
            <Bullet>Anleitung zum selbstständigen Arbeiten</Bullet>
            <Bullet>
              Seminarwochen und Fortbildungen mit anderen FSJler*innen in
              Tübingen
            </Bullet>
            <Bullet>Taschengeld 350 Euro / Monat</Bullet>
          </BulletList>

          <Paragraph>
            Träger des FSJ Kultur im Hölderlinturm ist der Internationale Bund.{" "}
            <TextLink href="https://www.internationaler-bund.de/standort/202502/">
              Dort erfärst du mehr
            </TextLink>{" "}
            über die Rahmenbedingungen des Freiwilligendienstes.
          </Paragraph>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Interesse?
          </Heading>
          <Paragraph>
            Wir freuen uns über eine kurze Bewerbung mit Motivationsschreiben
            und Lebenslauf per Mail an:
          </Paragraph>
          <Paragraph>
            <TextLink href="mailto:hoelderlinturm@tuebingen.de">
              hoelderlinturm@tuebingen.de
            </TextLink>{" "}
            <br />
            Ansprechpartner: Florian Mittelhammer (Leitender Kurator)
          </Paragraph>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default MitarbeitFsj
